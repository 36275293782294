import React from "react"
import { 
    Link,
    graphql 
} from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { rhythm } from "../../utils/typography"

const getProject = ({fluid, header, text, path, areaOfFocus }) => {
    return (
        <Project style={{
            marginBottom: '20px',
        }}>
            <StyledCardImage fluid={fluid} />
            <div style={{ width: '95%', margin: '0 auto',}}>
                <CardHeader>
                  {header}
                </CardHeader>
                <span style={{ fontWeight: '500', fontStyle: 'italic', lineHeight: '1.2', display: 'block', marginBottom: '5px'}}>{areaOfFocus}</span>
                <CardText>{text}</CardText>
                {!!path && (
                  <CardText style={{ marginBottom: '30px'}}><StyledLink to={`/portfolio${path}`}>View Case Study</StyledLink></CardText>
                )}
            </div>
        </Project>
    )
}

class Portfolio extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={this.props.location} title={siteTitle}>
            <SEO title="Portfolio" />
            <ApproachContainer >
                <StyledImage fluid={data.mainPortfolioImage.childImageSharp.fluid} />
                <Approach>
                    <h2 style={{ marginBottom: '10px',}}>My Approach</h2>
                    <Text>
                        In 2014, I was introduced to <span className="bold color">human-centered design</span> and it's been my guiding principle ever since.
                    </Text>
                    <Text>
                      I strongly believe that when we listen—actively listen and ask questions from a place of curiosity—we can make everlasting connections and gain deep insights.
                    </Text>
                    <Text>
                      As IDEO puts it, <span className="bold italic">"When you understand the people you’re trying to reach—and then design from their perspective—not only will you arrive at unexpected answers, but you’ll come up with ideas that they’ll actually embrace."</span></Text>
                </Approach>
            </ApproachContainer>
            <div style={{
                padding: rhythm(1.5),
                paddingTop: '10px',
            }}>
                <h2 style={{ marginBottom: '15px',}}>Select Projects Include</h2>
                <ProjectContainer>
                    {getProject({
                        fluid: data.project1.childImageSharp.fluid, 
                        header: `SangNamGan: Uncovering Community Projects and Motivations`,
                        text: `Directed a yearlong social venture in rural Lao PDR, focused on shifting spending behavior, empowerment, and co-design.`,
                        location: this.props.location,
                        path: '/SangNamGan',
                        areaOfFocus: 'User Research • Co-Design'
                    })} 
                    {getProject({
                        fluid: data.project2.childImageSharp.fluid, 
                        header: `Proximity School: Gathering Professional Development Needs`,
                        text: `Surveyed 300+ employees on training goals and desires, utilizing contextual inquiries, in-person interviews and ride-a-longs to understand their daily routines.`,
                        location: this.props.location,
                        path: '',
                        areaOfFocus: 'Qualitative Research • Instructional Design'
                    })} 
                    {getProject({
                        fluid: data.project3.childImageSharp.fluid, 
                        header: `On-the-Go English Class: Viber Pilot Program`,
                        text: `Piloted mobile language classes for two groups of Proximity sales associates, adapting an existing communication tool for learning & development.`,
                        location: this.props.location,
                        path: '/OnTheGoEnglishClass',
                        areaOfFocus: 'User Research • Prototyping'
                    })} 
                </ProjectContainer>
            </div>
        </Layout>
    )
  }
}

const ApproachContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1100px) {
    padding: 0 ${rhythm(1.5)} ${rhythm(1.5)};
  }
`;

const StyledImage = styled(Image)`
  flex: 0 1 100%;
  padding: 0 ${rhythm(1.5)} ${rhythm(1.5)};

  @media (min-width: 1100px) {
    flex-basis: 50%;
    margin: 0 30px 0 0;
    padding: 0;
  }
`;

const Approach = styled.div`
  flex: 0 1 100%;
  padding: ${rhythm(1.5)} ${rhythm(1.5)};

  @media (min-width: 1100px) {
    flex-basis: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 0;
    padding: 0;
  }
`;

const ProjectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 769px) {
    flex-direction: row;
  }
`;

const Project = styled.div`
  margin-bottom: 20px;
  flex: 0 0 100%;

  @media (min-width: 769px) {
    flex-basis: 48%;
  }

  @media (min-width: 1000px) {
    flex-basis: 31%;
  }
`;

const CardHeader = styled.h3`
    margin: -20px 0 5px;
    background-image: linear-gradient(to right, #f9a83d 0%, #fab356 100%);
    padding: 10px;
    font-size: 1.2rem;
`;

const StyledCardImage = styled(Image)`
  z-index: -20;
`;

const CardText = styled.p`
  margin-bottom: 5px;
`;

const Text = styled.p`
  margin-bottom: 10px;

  a {
    color: #000;
    font-weight: 600;

    :hover {
      opacity: .75;
    }
  }

  span.bold {
    font-weight: 600;
  }

  span.break {
    word-break: break-all;
  }

  span.italic {
    font-style: italic;
  }

  span.color {
    color: #f9a83d;
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  box-shadow: none;
  margin-bottom: 10px;
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid #f9a83d;
  color: #f9a83d;

  :hover {
    color: #f9a83d;
    opacity: .4;
  }
`;

export default Portfolio

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mainPortfolioImage: file(absolutePath: { regex: "/main-portfolio-pic.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
    }
    project1: file(absolutePath: { regex: "/portfolio1.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            }
        }
    }
    project2: file(absolutePath: { regex: "/portfolio2.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 600){
            ...GatsbyImageSharpFluid
            }
        }
    }
    project3: file(absolutePath: { regex: "/portfolio3.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 600){
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`